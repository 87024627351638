import React, {useReducer} from 'react';

import igdbContext from '../../context/igdb/igbdContext';
import igdbReducer from '../../context/igdb/igdbReducer';

import clienteAxios from '../../config/axios';

import {JUEGO_IGDB, LIMPIAR_FORMULARIO_IGDB, CARGANDO} from '../../types';

const IgdbState = props => {

    const initialState = {
        cargando: null,
        juego_igdb: null
    }

    const [state, dispatch] = useReducer(igdbReducer, initialState);

    const obtenerJuego = async idJuego => {
        const respuesta = await clienteAxios.post('/igdb', idJuego);
        // console.log(respuesta.data);
        dispatch({
            type: JUEGO_IGDB,
            payload: respuesta.data
        });
    }

    const limpiarFomularioIgdb = () => {
        dispatch({
            type: LIMPIAR_FORMULARIO_IGDB
        })
    }

    const setCargando = () => {
        dispatch({
            type: CARGANDO
        });
    }

    return ( 
        <igdbContext.Provider
            value={{
                cargando: state.cargando,
                juego_igdb: state.juego_igdb,
                obtenerJuego,
                limpiarFomularioIgdb,
                setCargando
            }}
        >
            {props.children}
        </igdbContext.Provider>
     );
}
 
export default IgdbState;