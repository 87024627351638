import {
    OBTENER_LINKS_MENU,
    SUBIR_LINKS_MENU,
    BORRAR_LINK_MENU
} from '../../types';

export default(state, action) => {
    switch(action.type) {
        case OBTENER_LINKS_MENU:
            return{
                ...state,
                linksmenu: action.payload
            }
        case SUBIR_LINKS_MENU:
            return{
                ...state,
                linksmenu: [action.payload, ...state.linksmenu]
            }
        case BORRAR_LINK_MENU:
            return{
                ...state,
                linksmenu: state.linksmenu.filter(item => item.id != action.payload)
            }
        default:
            return state;
    }
}