import React, {useReducer} from 'react';

import AdministracionContext from './administracionContext';
import AdministracionReducer from './administracionReducer';

import clienteAxios from '../../config/axios';
import basicAuth from '../../config/basicAuth';

import {
    PRINCIPAL,
    SUBIR_JUEGO,
    BANNER,
    PUBLICIDAD,
    USUARIOS,
    LINKS_MENU,
    POPUPS,
    PAPELERA,
    CONFIGURACION
} from '../../types';

const AdministracionState = props => {
    const initialState = {
        principal: true,
        subirjuego: null,
        banner: null,
        publicidad: null,
        usuarios: null,
        linksmenu: null,
        popups: null,
        papelera: null,
        configuracion: null
    }

    const [state, dispatch] = useReducer(AdministracionReducer, initialState);

    //funciones
    const mostrarPrincipal = () =>{
        dispatch({
            type: PRINCIPAL
        })
    }

    const mostrarSubirJuego = () =>{
        dispatch({
            type: SUBIR_JUEGO
        })
    }

    const mostrarBanner = () =>{
        dispatch({
            type: BANNER
        })
    }

    const mostrarPublicidad = () => {
        dispatch({
            type: PUBLICIDAD
        })
    }

    const mostrarUsuarios = () => {
        dispatch({
            type: USUARIOS
        })
    }

    const mostrarLinksmenu = () => {
        dispatch({
            type: LINKS_MENU
        })
    }

    const mostrarPopups = () => {
        dispatch({
            type: POPUPS
        })
    }

    const mostrarPapelera = () => {
        dispatch({
            type: PAPELERA
        })
    }

    const mostrarConfiguracion = () => {
        dispatch({
            type: CONFIGURACION
        })
    }

    return ( 
        <AdministracionContext.Provider
            value={{
                principal: state.principal,
                subirjuego: state.subirjuego,
                banner: state.banner,
                publicidad: state.publicidad,
                usuarios: state.usuarios,
                linksmenu: state.linksmenu,
                popups: state.popups,
                papelera: state.papelera,
                configuracion: state.configuracion,
                mostrarPrincipal,
                mostrarSubirJuego,
                mostrarBanner,
                mostrarPublicidad,
                mostrarUsuarios,
                mostrarLinksmenu,
                mostrarPopups,
                mostrarPapelera,
                mostrarConfiguracion
            }}
        >
            {props.children}
        </AdministracionContext.Provider>
     );
}
 
export default AdministracionState;