import {
    OBTENER_PAPELERA,
    BORRAR_JUEGO_PAPELERA,
    RESTAURAR_JUEGO,
    BORRAR_TODO
} from '../../types';

export default (state, action) => {
    switch(action.type) {
        case OBTENER_PAPELERA:
            return {
                ...state,
                juegospapelera: action.payload
            }
        case BORRAR_JUEGO_PAPELERA:
            return {
                ...state,
                juegospapelera: state.juegospapelera.filter(juego => juego.id !== action.payload)
            }
        case RESTAURAR_JUEGO:
            return {
                ...state,
                juegospapelera: state.juegospapelera.filter(juego => juego.id !== action.payload)
            }
        default:
            return state;
    }
}