export const OBTENER_JUEGOS = 'OBTENER_JUEGOS';
export const JUEGOS_ALEATORIOS = 'JUEGOS_ALEATORIOS';
export const JUEGO_ACTUAL = 'JUEGO_ACTUAL';
export const LIMPIAR_JUEGO_ACTUAL = 'LIMPIAR_JUEGO_ACTUAL';
export const BORRAR_JUEGO = 'BORRAR_JUEGO';
export const JUEGO_PARA_EDITAR = 'JUEGO_PARA_EDITAR';
export const LIMPIAR_FORMULARIO_EDITAR_JUEGO = 'LIMPIAR_FORMULARIO_EDITAR_JUEGO';
export const ABRIR_DESCARGA = 'ABRIR_DESCARGA';
export const CERRAR_DESCARGA = 'CERRAR_DESCARGA';
export const ABRIR_TRAILER = 'ABRIR_TRAILER';
export const CERRAR_TRAILER = 'CERRAR_TRAILER';
export const ABRIR_GAMEPLAY = 'ABRIR_GAMEPLAY';
export const CERRAR_GAMEPLAY = 'CERRAR_GAMEPLAY';

export const REGISTRO_EXITOSO = 'REGISTRO_EXITOSO';
export const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
export const EDITAR_EXITOSO = 'EDITAR_EXITOSO';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REGISTRO_ERROR = 'REGISTRO_ERROR';
export const USUARIO_AUTENTICADO = 'USUARIO_AUTENTICADO';
export const CERRAR_SESION = 'CERRAR_SESION';
export const OBTENER_USUARIOS = 'OBTENER_USUARIOS';
export const BORRAR_USUARIO = 'BORRAR_USUARIO';
export const COVER_ACTUAL = 'COVER_ACTUAL';
export const PORTADA_ACTUAL = 'PORTADA_ACTUAL';
export const BORRAR_USUARIOS_INACTIVOS = 'BORRAR_USUARIOS_INACTIVOS';

export const PRINCIPAL = 'PRINCIPAL';
export const SUBIR_JUEGO = 'SUBIR_JUEGO';
export const BANNER = 'BANNER';
export const PUBLICIDAD = 'PUBLICIDAD';
export const USUARIOS = 'USUARIOS';
export const LINKS_MENU = 'LINKS_MENU';
export const POPUPS = 'POPUPS';
export const PAPELERA = 'PAPELERA';
export const CONFIGURACION = 'CONFIGURACION';

export const OBTENER_BANNERS = 'OBTENER_BANNERS';
export const MODIFICAR_ESTADO_BANNERS = 'MODIFICAR_ESTADO_BANNERS';

export const OBTENER_PAPELERA = 'OBTENER_PAPELERA';
export const BORRAR_JUEGO_PAPELERA = 'BORRAR_JUEGO_PAPELERA';
export const RESTAURAR_JUEGO = 'RESTAURAR_JUEGO';
export const BORRAR_TODO = 'BORRAR_TODO';

export const PAGINA_ACTUAL =  'PAGINA_ACTUAL';
export const PAGINA_REF = 'PAGINA_REF';
export const FILTRAR_NOMBRE = 'FILTRAR_NOMBRE';
export const LIMPIAR_FILTRADO = 'LIMPIAR_FILTRADO';
export const FILTRAR_JUEGOSPC = 'FILTRAR_JUEGOSPC';
export const GUARDAR_PAGINA = 'GUARDAR_PAGINA';

export const ESTADO_JUEGO = 'ESTADO_JUEGO';
export const SUBIR_ESTADO = 'SUBIR_ESTADO';
export const BAJAR_ESTADO = 'BAJAR_ESTADO';
export const ALL_ESTADOS = 'ALL_ESTADOS';
export const LIMPIAR_ESTADOS = 'LIMPIAR_ESTADOS';
export const FAVORITO = 'FAVORITO';
export const PENDIENTE = 'PENDIENTE';
export const FINALIZADO = 'FINALIZADO';
export const EDITAR = 'EDITAR';
export const BAJAR_ESTADO_PROFILE = 'BAJAR_ESTADO_PROFILE';
export const SUBIR_ESTADO_MAIN = 'SUBIR_ESTADO_MAIN';

export const JUEGO_IGDB = 'JUEGO_IGDB';
export const LIMPIAR_FORMULARIO_IGDB = 'LIMPIAR_FORMULARIO_IGDB';
export const CARGANDO = 'CARGANDO';

export const OBTENER_PUBLICIDAD = 'OBTENER_PUBLICIDAD';

export const USUARIOS_ALEATORIOS = 'USUARIOS_ALEATORIOS';

export const OBTENER_LINKS_MENU = 'OBTENER_LINKS_MENU';
export const SUBIR_LINKS_MENU = 'SUBIR_LINKS_MENU';
export const BORRAR_LINK_MENU = 'BORRAR_LINK_MENU';

export const OBTENER_IMAGENES = 'OBTENER_IMAGENES';
export const BORRAR_IMAGEN = 'BORRAR_IMAGEN';
export const NUEVA_IMAGEN = 'NUEVA_IMAGEN';

export const OBTENER_PERFIL = 'OBTENER_PERFIL';