import {
    ESTADO_JUEGO,
    SUBIR_ESTADO,
    BAJAR_ESTADO,
    ALL_ESTADOS,
    LIMPIAR_ESTADOS,
    FAVORITO,
    PENDIENTE,
    FINALIZADO,
    EDITAR,
    BAJAR_ESTADO_PROFILE,
    SUBIR_ESTADO_MAIN
} from '../../types';

export default(state, action) => {
    switch(action.type) {
        case ESTADO_JUEGO:
            return {
                ...state,
                estadojuego: action.payload
            }
        case SUBIR_ESTADO:
            return {
                ...state,
                estadojuego: state.estadojuego.map((estado, index) => index == action.payload ? "1" : estado)
            }
        case BAJAR_ESTADO:
            return {
                ...state,
                estadojuego: state.estadojuego.map((estado, index) => index == action.payload ? "0" : estado)
            }
        case BAJAR_ESTADO_PROFILE:
            if(action.payload['estado'] == 0){
                return {
                    ...state,
                    estadofavorito: state.estadofavorito.filter(juego => juego.id !== action.payload['id_juego'])
                }
            }else if(action.payload['estado'] == 1) {
                return {
                    ...state,
                    estadopendiente: state.estadopendiente.filter(juego => juego.id != action.payload['id_juego'])
                }
            }else if(action.payload['estado'] == 2) {
                return {
                    ...state,
                    estadofinalizado: state.estadofinalizado.filter(juego => juego.id != action.payload['id_juego'])
                }
            }
        case SUBIR_ESTADO_MAIN:
            return {
                ...state,
                estadofavorito: [action.payload, ...state.estadofavorito]
            }
        case ALL_ESTADOS:
            return {
                ...state,
                estadofavorito: action.payload[0],
                estadopendiente: action.payload[1],
                estadofinalizado: action.payload[2]
            }
        case LIMPIAR_ESTADOS:
            return {
                ...state,
                estadojuego: null
            }
        case FAVORITO:
            return {
                ...state,
                favorito: true,
                pendiente: null,
                finalizado: null,
                editar: false
            }
        case PENDIENTE:
            return {
                ...state,
                favorito: null,
                pendiente: true,
                finalizado: null,
                editar: false
            }
        case FINALIZADO:
            return {
                ...state,
                favorito: null,
                pendiente: null,
                finalizado: true,
                editar: false    
            }
        case EDITAR: 
        return {
            ...state,
            favorito: null,
            pendientes: null,
            finalizado: null,
            editar: true
        }
        default:
            return state;
    }
}