import { OBTENER_PERFIL } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case OBTENER_PERFIL:
      // console.log(action.payload[1])
      return {
        ...state,
        usuarioView: action.payload[0],
        cargando: false,
        isLogged: action.payload[1]
      };
    default:
      return state;
  }
};
