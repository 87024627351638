import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';
import basicAuth from '../../config/basicAuth';

import bannerContext from '../../context/banner/bannerContext';
import bannerReducer from '../../context/banner/bannerReducer';

import {OBTENER_BANNERS, MODIFICAR_ESTADO_BANNERS} from '../../types';

const BannerState = props => {

    const initialState = {
        banners: null,
        estadobanners: null
    }

    const [state, dispatch] = useReducer(bannerReducer, initialState);

    const subirBanner = async datos => {
        basicAuth();
        const respuesta = await clienteAxios.post('/banner', datos);
        // console.log(respuesta.data);
    }

    const obtenerBanners = async datos => {
        const respuesta = await clienteAxios.get('/banner/'+datos);
        // console.log(respuesta.data);
        if(respuesta.status === 200) {
            dispatch({
                type: OBTENER_BANNERS,
                payload: respuesta.data
            });
        }
    }

    const modificarEstadoBanner = (data) => {
        dispatch({
            type: MODIFICAR_ESTADO_BANNERS,
            payload: data
        })
    }

    return ( 
        <bannerContext.Provider
            value={{
                banners: state.banners,
                estadobanners: state.estadobanners,
                subirBanner,
                obtenerBanners,
                modificarEstadoBanner
            }}
        >
            {props.children}
        </bannerContext.Provider>
     );
}
 
export default BannerState;