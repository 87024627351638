const routes = {
    url: 'https://zonecrack.com',
    production: 'https://zonecrack.com',
    home: '/',
    login: '/login',
    register: '/register',
    juegospc: '/juegospc',
    profile: {
        profile: '/:id',
        profileDefault: '/profile'
    }
    
}

export default routes;