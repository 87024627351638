import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';
import basicAuth from '../../config/basicAuth';

import AvatarContext from './avatarContext';
import AvatarReducer from './avatarReducer';

import {OBTENER_IMAGENES, BORRAR_IMAGEN} from '../../types';

const AvatarState = props => {

    const initialState = {
        avatares: null
    }

    const [state, dispatch] = useReducer(AvatarReducer, initialState);

    const getAvatares = async () => {
        try {
            const respuesta = await clienteAxios.get('/avatares');
            // console.log(respuesta.data);
            dispatch({
                type: OBTENER_IMAGENES,
                payload: respuesta.data
            })
        } catch (error) {
            
        }
    }

    const subirArchivosAvatares = async (files) => {
        basicAuth();
        try {
            const respuesta = await clienteAxios.post('/avatares', files);
            // console.log(respuesta.data);
        } catch (error) {
            console.log(error);
        }
    }

    const borrarAvatar = async (id) => {
        basicAuth();
        try {
            const respuesta = await clienteAxios.delete('/avatares/'+id);
            // console.log(respuesta.data);
            dispatch({
                type: BORRAR_IMAGEN,
                payload: id
            });
        } catch (error) {
            console.log(error);
        }
    }

    return ( 
        <AvatarContext.Provider
            value={{
                avatares:state.avatares,
                getAvatares,
                subirArchivosAvatares,
                borrarAvatar
            }}
        >
            {props.children}
        </AvatarContext.Provider>
     );
}
 
export default AvatarState;