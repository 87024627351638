import React, {useReducer} from 'react';

import papeleraContext from './papeleraContext';
import papeleraReducer from './papeleraReducer';

import clienteAxios from '../../config/axios';
import basicAuth from '../../config/basicAuth';

import {
    OBTENER_PAPELERA,
    BORRAR_JUEGO_PAPELERA,
    RESTAURAR_JUEGO,
    BORRAR_TODO
} from '../../types';

const PapeleraState = props => {

    const initialState = {
        juegospapelera: []
    }

    const [state, dispatch] = useReducer(papeleraReducer, initialState);

    //funciones
    const obtenerPapelera = async datos => {
        const respuesta = await clienteAxios.get('/papelera');
        // console.log(respuesta.status);
        if(respuesta.status === 200){
            dispatch({
                type: OBTENER_PAPELERA,
                payload: respuesta.data
            })
        }
    }

    const borrarJuego = async id => {
        basicAuth();
        const  respuesta = await clienteAxios.delete('/papelera/'+id);
        if(respuesta.status === 200){
            dispatch({
                type: BORRAR_JUEGO_PAPELERA,
                payload: id
            })
        }
    }

    const restaurarJuego = async id => {
        basicAuth();
        const  respuesta = await clienteAxios.post('/papelera/restaurar/'+id);
        // console.log(respuesta);
        if(respuesta.status === 200){
            dispatch({
                type: RESTAURAR_JUEGO,
                payload: id
            })
        }
    }

    const borrarTodo = async data => {
        basicAuth();
        const respuesta = await clienteAxios.delete('/papelera');
    }

    return ( 
        <papeleraContext.Provider
            value={{
                juegospapelera: state.juegospapelera,
                obtenerPapelera,
                borrarJuego,
                restaurarJuego,
                borrarTodo
            }}
        >
            {props.children}
        </papeleraContext.Provider>
     );
}
 
export default PapeleraState;