import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';
import basicAuth from '../../config/basicAuth';

import userContext from '../../context/user/userContext';
import userReducer from '../../context/user/userReducer';

import { USUARIOS_ALEATORIOS } from '../../types';

const UserState = props => {

    const initialState = {
        usuarios_aleatorios: null
    }

    const [state, dispatch] = useReducer(userReducer, initialState);

    const usuariosAleatorios = async nUsuarios =>{
        const respuesta = await clienteAxios.post('/usuariosaleatorios', nUsuarios);
        // console.log(respuesta.data);
        if(respuesta.status === 200){
            dispatch({
                type: USUARIOS_ALEATORIOS,
                payload: respuesta.data
            });
        }
    }

    return ( 
        <userContext.Provider
            value={{
                usuarios_aleatorios: state.usuarios_aleatorios,
                usuariosAleatorios
            }}
        >
            {props.children}
        </userContext.Provider>
     );
}
 
export default UserState;