import React from "react";
import laodImage from "../../img/logo.gif";
import SpinnerCargando from "../../img/cargando.gif";


const LaodingComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        background: "#000",
        transition: ".2s all",
      }}
    >
      <img
        width="200"
        style={{ transition: ".2s all" }}
        src={laodImage}
        loading="lazy"
      />
      <img width="200" src={SpinnerCargando} />
    </div>
  );
};

export default LaodingComponent;
