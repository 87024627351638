import React, {useReducer} from 'react';
import clienteAxios from '../../config/axios';
import tokenAuth from '../../config/token';

import estadoContext from './estadoContext';
import estadoReducer from './estadoReducer';

import {
    ESTADO_JUEGO,
    SUBIR_ESTADO,
    BAJAR_ESTADO,
    ALL_ESTADOS,
    LIMPIAR_ESTADOS,
    FAVORITO,
    PENDIENTE,
    FINALIZADO,
    EDITAR,
    BAJAR_ESTADO_PROFILE,
    SUBIR_ESTADO_MAIN
} from '../../types';

const EstadoState = props => {
    const initialState = {
        estadojuego: ["0","0","0"],
        estadofavorito: null,
        estadopendiente: null,
        estadofinalizado: null,
        favorito: true,
        pendiente: null,
        finalizado: null,
        editar: null
    }

    const [state, dispatch] = useReducer(estadoReducer, initialState);

    const getEstado = async data => {
        const token = localStorage.getItem('zonecrack_token');
        if(token) {
            tokenAuth(token);
            const respuesta = await clienteAxios.get('/estado/'+data['id_usuario']+"/"+data['id_juego']);
            // console.log(respuesta.data.data);
            if(respuesta.data.status === 200){
                // console.log(respuesta.data.data[0]);
                dispatch({
                    type: ESTADO_JUEGO,
                    payload: respuesta.data.data
                })
            }
        }
    }

    const upEstado = async (data, profile, juego) => {
        const token = localStorage.getItem('zonecrack_token');
        if(token) {
            tokenAuth(token);
            const respuesta = await clienteAxios.post('/estado', data);
            if(respuesta.data.status === 200){
                // console.log(respuesta.data.data);
                if(profile == 0){
                    if(data['tipo'] == 0){
                        dispatch({
                            type: SUBIR_ESTADO,
                            payload: data['estado']
                        });
                        dispatch({
                            type: SUBIR_ESTADO_MAIN,
                            payload: juego
                        })
                    }else{
                        dispatch({
                            type: BAJAR_ESTADO,
                            payload: data['estado']
                        });
                        dispatch({
                            type: BAJAR_ESTADO_PROFILE,
                            payload: data
                        })
                    }
                }else{
                    dispatch({
                        type: BAJAR_ESTADO_PROFILE,
                        payload: data
                    })
                }
            }
        }
    }

    const getAllEstados = async userId => {
        const respuesta = await clienteAxios.post('/allestados', userId);
        // console.log(respuesta.data);
        if(respuesta.data.status === 200){
            // console.log(respuesta.data.data);
            dispatch({
                type: ALL_ESTADOS,
                payload: respuesta.data.data
            });
        }
    }

    const limpiarEstados = () => {
        dispatch({
            type: LIMPIAR_ESTADOS
        })
    }

    const mostrarFavorito = () => {
        dispatch({
            type: FAVORITO
        });
    }

    const mostrarPendiente = () => {
        dispatch({
            type: PENDIENTE
        });
    }

    const mostrarFinalizado = () => {
        dispatch({
            type: FINALIZADO
        });
    }

    const mostrarEditar = () => {
        dispatch({
            type: EDITAR
        })
    }

    return (
        <estadoContext.Provider
            value={{
                estadojuego: state.estadojuego,
                estadofavorito: state.estadofavorito,
                estadopendiente: state.estadopendiente,
                estadofinalizado: state.estadofinalizado,
                favorito: state.favorito,
                pendiente: state.pendiente,
                finalizado: state.finalizado,
                editar: state.editar,
                getEstado,
                upEstado,
                getAllEstados,
                limpiarEstados,
                mostrarFavorito,
                mostrarPendiente,
                mostrarFinalizado,
                mostrarEditar
            }}
        >
            {props.children}
        </estadoContext.Provider>
    );
}

export default EstadoState;