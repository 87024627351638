import React, {useReducer} from 'react';

import clienteAxios from '../../config/axios';

import filtroContext from './filtroContext';
import filtroReducer from './filtroReducer';

import {
    PAGINA_ACTUAL,
    PAGINA_REF,
    FILTRAR_NOMBRE,
    LIMPIAR_FILTRADO,
    FILTRAR_JUEGOSPC,
    GUARDAR_PAGINA
} from '../../types';

const FiltroState = props => {
    const initialState = {
        paginaactual : 1,
        paginaref: 1,
        njuegos: null,
        juegosfiltrados: null,
        nombrefiltrado: '',
        juegospcfiltrados : null,
        juegosporpagina: null,
    }

    const [state, dispatch] = useReducer(filtroReducer, initialState);

    const setPaginaActual = (pagina) => {
        dispatch({
            type: PAGINA_ACTUAL,
            payload: pagina
        })
    }

    const setPaginaRef = (ref) => {
        dispatch({
            type: PAGINA_REF,
            payload: ref
        })
    }

    const filtradoNombre = (nombre, juegos) => {
        const data = [nombre, juegos];
        if(nombre.length < 2){
            dispatch({
                type: LIMPIAR_FILTRADO,
                payload: nombre
            })
            return
        }
        dispatch({
            type: FILTRAR_NOMBRE,
            payload: data
        })
    }

    const filtro = async data =>{
        const respuesta = await clienteAxios.post('/filtro', data);
        // console.log(respuesta.data);
        if(respuesta.status === 200){
            dispatch({
                type: FILTRAR_JUEGOSPC,
                payload: respuesta.data
            })
        }
    }

    const guardarPagina = data => {
        dispatch({
            type: GUARDAR_PAGINA,
            payload: data
        })
    }

    return (
        <filtroContext.Provider
            value={{
                paginaactual: state.paginaactual,
                paginaref: state.paginaref,
                njuegos: state.njuegos,
                juegosfiltrados: state.juegosfiltrados,
                nombrefiltrado: state.nombrefiltrado,
                juegospcfiltrados: state.juegospcfiltrados,
                juegosporpagina: state.juegosporpagina,
                setPaginaActual,
                setPaginaRef,
                filtradoNombre,
                filtro,
                guardarPagina
            }}
        >
            {props.children}
        </filtroContext.Provider>
    );
}

export default FiltroState;