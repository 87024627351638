import React, {useReducer} from 'react';
import clienteAxios from '../../config/axios';
import basicAuth from '../../config/basicAuth';

import linksmenuContext from '../../context/linksMenu/linksmenuContext';
import linksmenuReducer from '../../context/linksMenu/linksmenuReducer';

import {
    OBTENER_LINKS_MENU,
    SUBIR_LINKS_MENU,
    BORRAR_LINK_MENU
} from '../../types';

const LinksmenuState = props => {
    const initialState = {
        linksmenu: null
    }

    const [state, dispatch] = useReducer(linksmenuReducer, initialState);

    const getLinksMenu = async data => {
        const respuesta = await clienteAxios.get('/menulinks');
        if(respuesta.status === 200) {
            dispatch({
                type: OBTENER_LINKS_MENU,
                payload: respuesta.data
            });
        }
    }

    const addLinkMenu = async datos => {
        basicAuth();
        const respuesta = await clienteAxios.post('/menulinks', datos);
        if(respuesta.status === 200){
            dispatch({
                type: SUBIR_LINKS_MENU,
                payload: datos
            });
        }
    }

    const deleteLinkMenu = async id => {
        basicAuth();
        const respuesta = await clienteAxios.delete('/menulinks/'+id);
        if(respuesta.status === 200){
            dispatch({
                type: BORRAR_LINK_MENU,
                payload: id
            });
        }
    }

    return (
        <linksmenuContext.Provider
            value={{
                linksmenu: state.linksmenu,
                getLinksMenu,
                addLinkMenu,
                deleteLinkMenu
            }}
        >
            {props.children}
        </linksmenuContext.Provider>
    )
}

export default LinksmenuState;