import {
    OBTENER_IMAGENES,
    BORRAR_IMAGEN
} from '../../types';

export default (state, action) => {
    switch(action.type){
        case OBTENER_IMAGENES:
            return {
                ...state,
                portadas: action.payload,
            }
        case BORRAR_IMAGEN:
            return {
                ...state,
                portadas: state.portadas.filter(portada => portada.id !== action.payload)
            }
        default:
            return state;
    }
}