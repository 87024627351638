import {OBTENER_BANNERS, MODIFICAR_ESTADO_BANNERS} from '../../types';

export default(state, action) => {
    switch(action.type) {
        case OBTENER_BANNERS:
            return {
                ...state,
                banners: action.payload[0],
                estadobanners: action.payload[1]
            }
        case MODIFICAR_ESTADO_BANNERS:
            return{
                ...state,
                estadobanners: action.payload
            }
        default:
            return state;
    }
}