import {OBTENER_PUBLICIDAD} from '../../types';

export default(state, action) => {
    switch(action.type) {
        case OBTENER_PUBLICIDAD:
            return {
                ...state,
                publicidad: action.payload
            }
        default:
            return state;
    }
}