import {USUARIOS_ALEATORIOS} from '../../types';

export default(state, action) => {
    switch(action.type) {
        
        case USUARIOS_ALEATORIOS:
            return  {
                ...state,
                usuarios_aleatorios: action.payload
            }

        default:
            return state;
    }
}