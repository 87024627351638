import {
    PRINCIPAL,
    SUBIR_JUEGO,
    BANNER,
    PUBLICIDAD,
    USUARIOS,
    LINKS_MENU,
    POPUPS,
    PAPELERA,
    CONFIGURACION
} from '../../types';

export default (state, action) => {
    switch(action.type) {
        case PRINCIPAL:
            return{
                ...state,
                principal: true,
                subirjuego: null,
                banner: null,
                publicidad: null,
                usuarios: null,
                linksmenu: null,
                popups: null,
                papelera: null,
                configuracion: null
            }
        case SUBIR_JUEGO:
            return{
                ...state,
                principal: null,
                subirjuego: true,
                banner: null,
                publicidad: null,
                usuarios: null,
                linksmenu: null,
                popups: null,
                papelera: null,
                configuracion: null
            }
        case BANNER:
            return{
                ...state,
                principal: null,
                subirjuego: null,
                banner: true,
                publicidad: null,
                usuarios: null,
                linksmenu: null,
                popups: null,
                papelera: null,
                configuracion: null
            }
        case PUBLICIDAD:
            return{
                ...state,
                principal: null,
                subirjuego: null,
                banner: null,
                publicidad: true,
                usuarios: null,
                linksmenu: null,
                popups: null,
                papelera: null,
                configuracion: null
            }
        case USUARIOS:
            return{
                ...state,
                principal: null,
                subirjuego: null,
                banner: null,
                publicidad: null,
                usuarios: true,
                linksmenu: null,
                popups: null,
                papelera: null,
                configuracion: null
            }
        case LINKS_MENU:
            return{
                ...state,
                principal: null,
                subirjuego: null,
                banner: null,
                publicidad: null,
                usuarios: null,
                linksmenu: true,
                popups: null,
                papelera: null,
                configuracion: null
            }
        case POPUPS:
            return{
                ...state,
                principal: null,
                subirjuego: null,
                banner: null,
                publicidad: null,
                usuarios: null,
                linksmenu: null,
                popups: true,
                papelera: null,
                configuracion: null
            }
        case PAPELERA:
            return{
                ...state,
                principal: null,
                subirjuego: null,
                banner: null,
                publicidad: null,
                usuarios: null,
                linksmenu: null,
                popups: null,
                papelera: true,
                configuracion: null
            }
        case CONFIGURACION:
            return{
                ...state,
                principal: null,
                subirjuego: null,
                banner: null,
                publicidad: null,
                usuarios: null,
                linksmenu: null,
                popups: null,
                papelera: null,
                configuracion: true
            }
        default:
            return state;
    }
}