import React, {useReducer} from 'react';
import clienteAxios from '../../config/axios';
import tokenAuth from '../../config/token';

import AuthContext from './authContext';
import AuthReducer from './authReducer';

import {
    REGISTRO_EXITOSO,
    LOGIN_EXITOSO,
    EDITAR_EXITOSO,
    LOGIN_ERROR,
    REGISTRO_ERROR,
    USUARIO_AUTENTICADO,
    CERRAR_SESION,
    OBTENER_USUARIOS,
    BORRAR_USUARIO,
    COVER_ACTUAL,
    PORTADA_ACTUAL,
    BORRAR_USUARIOS_INACTIVOS
} from '../../types';
import basicAuth from '../../config/basicAuth';

const AuthState = props => {

    const initialState = {
        token: localStorage.getItem('zonecrack_token'),
        autenticado: null,
        usuario: null,
        cargando: true,
        rol: null,
        usuarios: [],
        portada: '2021_7_4_19_14_46_15.png',
        cover: 'chun-li.png'
    }

    const [state, dispatch] = useReducer(AuthReducer, initialState);

    //funciones
    const registrarUsuario = async datos => {
        try {
            const respuesta = await clienteAxios.post('/registro', datos);
            // console.log(respuesta.data);
            if(respuesta.data.status === 200) {
                dispatch({
                    type: REGISTRO_EXITOSO,
                    payload: respuesta.data
                })
                usuarioAutenticado();
            }else{
                dispatch({
                    type: REGISTRO_ERROR
                })
            }
            
        }catch(error) {
            
        }
    }

    const iniciarSesion = async datos => {
        try {
            const respuesta = await clienteAxios.post('/user', datos);
            // console.log(respuesta)
            if(respuesta.data.status === 200){
                dispatch({
                    type: LOGIN_EXITOSO,
                    payload: respuesta.data
                })
                usuarioAutenticado();
            }else{
                dispatch({
                    type: LOGIN_ERROR,
                })
            }
        }  catch (error) {
            // console.log(error.response.data.msg);
            // const alerta = {
            //     msg: error.response.data.msg,
            //     categoria: 'alerta-error'
            // }

            // dispatch({
            //     type: LOGIN_ERROR,
            //     payload: alerta
            // })
        }
    }

    const usuarioAutenticado = async () => {
        const token = localStorage.getItem('zonecrack_token');
        // console.log(token);
        if(token) {
            tokenAuth(token);
            const respuesta = await clienteAxios.get('/user');
            if(respuesta.data.status === 200){
                // console.log(respuesta.data.data[0]);
                dispatch({
                    type: USUARIO_AUTENTICADO,
                    payload: respuesta.data.data[0]
                })
            }else{
                console.log("Error Numero "+respuesta.data.alv);
                cerrarSesion();
            }
        }
    }

    //cierra la sesión del usuario
    const cerrarSesion = () => {
        dispatch({
            type: CERRAR_SESION
        })
    }

    const obtenerUsuarios = async data => {
        basicAuth();
        const respuesta = await clienteAxios.get('/allusers');
        // console.log(respuesta.data.data);
        if(respuesta.data.status === 200){
            // console.log(respuesta.data.data);
            dispatch({
                type: OBTENER_USUARIOS,
                payload: respuesta.data.data
            })
        }
    }

    const borrarUsuario = async id => {
        basicAuth();
        const respuesta = await clienteAxios.delete('/user/'+id);
        // console.log(id);
        dispatch({
            type: BORRAR_USUARIO,
            payload: id
        })
    }

    const coverActual = nombre => {
        dispatch({
            type: COVER_ACTUAL,
            payload: nombre
        })
    }

    const portadaActual = nombre => {
        dispatch({
            type: PORTADA_ACTUAL,
            payload: nombre
        })
    }

    const editarPerfil = async data => {
        const respuesta = await clienteAxios.post('/profile', data);
        // console.log(respuesta.data.token);
        if(respuesta.data.status === 200){
            dispatch({
                type: EDITAR_EXITOSO,
                payload: respuesta.data
            })
            usuarioAutenticado();
        }
    }

    const borrarUsuariosInactivos = async data => {
        basicAuth();
        const respuesta = await clienteAxios.delete('/user');
    }

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                autenticado: state.autenticado,
                usuario: state.usuario,
                cargando: state.cargando,
                usuarios: state.usuarios,
                portada: state.portada,
                cover: state.cover,
                registrarUsuario,
                iniciarSesion,
                usuarioAutenticado,
                cerrarSesion,
                obtenerUsuarios,
                borrarUsuario,
                coverActual,
                portadaActual,
                editarPerfil,
                borrarUsuariosInactivos
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState;