import React, {useReducer} from 'react';
import clienteAxios from '../../config/axios';
import basicAuth from '../../config/basicAuth';

//import {v4 as uuid} from 'uuid';

import mainContext from './mainContext';
import mainReducer from './mainReducer';

// import Miniatura1 from "../../img/items-5.jpg";
// import Miniatura2 from "../../img/items-4.jpg";

import {
    OBTENER_JUEGOS,
    JUEGOS_ALEATORIOS,
    JUEGO_ACTUAL,
    LIMPIAR_JUEGO_ACTUAL,
    BORRAR_JUEGO,
    JUEGO_PARA_EDITAR,
    LIMPIAR_FORMULARIO_EDITAR_JUEGO,
    ABRIR_DESCARGA,
    CERRAR_DESCARGA,
    ABRIR_TRAILER,
    CERRAR_TRAILER,
    ABRIR_GAMEPLAY,
    CERRAR_GAMEPLAY
} from '../../types';

const MainState = props => {

    const initialState = {
        juegos: [],
        juego: null,
        juegoeditar: null,
        juegosaleatorios: [],
        popupdescarga: false,
        popuptrailer: false,
        popupgameplay: false
    }

    //dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(mainReducer, initialState);

    const obtenerJuegos = async datos => {
        try {
            const respuesta = await clienteAxios.get('/game');
            // console.log(respuesta.data);
            dispatch({
                type: OBTENER_JUEGOS,
                payload: respuesta.data
            })
        } catch (error) {
            
        }
    }

    const getJuegosAleatorios = async nJuegos=> {
        const respuesta = await clienteAxios.post('/juegosaleatorios', nJuegos);
        // console.log(respuesta.data);
        if(respuesta.status === 200){
            dispatch({
                type: JUEGOS_ALEATORIOS,
                payload: respuesta.data
            })
        }
    }

    const juegoActual = async juegoId => {
        basicAuth();
        const respuesta = await clienteAxios.get('/game/'+juegoId);
        dispatch({
            type: JUEGO_ACTUAL,
            payload: respuesta.data
        })
    }

    const limpiarJuegoActual = () => {
        dispatch({
            type: LIMPIAR_JUEGO_ACTUAL
        })
    }

    const borrarJuego = async juegoId => {
        basicAuth();
        const  respuesta = await clienteAxios.delete('/game/'+juegoId);
        if(respuesta.status === 200){
            dispatch({
                type: BORRAR_JUEGO,
                payload: juegoId
            })
        }
    }

    const subirJuego = async datos => {
        basicAuth();
        const respuesta = await clienteAxios.post('/game', datos);
        console.log(datos);
    }

    const juegoParaEditar = async id => {
        basicAuth();
        const respuesta = await clienteAxios.get('/game/'+id);
        // console.log(respuesta.data);
        dispatch({
            type: JUEGO_PARA_EDITAR,
            payload: respuesta.data
        })
    }

    const editarJuego = async juego => {
        basicAuth();
        const respuesta = await clienteAxios.put('/game', juego);
        // console.log(respuesta.data.DETAILS);
    }

    const limpiarFormulario = () => {
        dispatch({
            type: LIMPIAR_FORMULARIO_EDITAR_JUEGO
        });
    }

    const abrirDescarga = () => {
        dispatch({
            type: ABRIR_DESCARGA
        })
    }

    const cerrarDescarga = () => {
        dispatch({
            type: CERRAR_DESCARGA
        })
    }

    const abrirTrailer = () => {
        dispatch({
            type: ABRIR_TRAILER
        })
    }

    const cerrarTrailer = () => {
        dispatch({
            type: CERRAR_TRAILER
        })
    }

    const abrirGameplay = () => {
        dispatch({
            type: ABRIR_GAMEPLAY
        })
    }

    const cerrarGameplay = () => {
        dispatch({
            type: CERRAR_GAMEPLAY
        })
    }

    return (
        <mainContext.Provider
            value={{
                juegos: state.juegos,
                juego: state.juego,
                juegoeditar: state.juegoeditar,
                juegosaleatorios: state.juegosaleatorios,
                popupdescarga: state.popupdescarga,
                popuptrailer: state.popuptrailer,
                popupgameplay: state.popupgameplay,
                obtenerJuegos,
                getJuegosAleatorios,
                juegoActual,
                limpiarJuegoActual,
                borrarJuego,
                subirJuego,
                juegoParaEditar,
                editarJuego,
                limpiarFormulario,
                abrirDescarga,
                cerrarDescarga,
                abrirTrailer,
                cerrarTrailer,
                abrirGameplay,
                cerrarGameplay
            }}
        >
            {props.children}
        </mainContext.Provider>
    )
}

export default MainState;