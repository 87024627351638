import {
    OBTENER_JUEGOS,
    JUEGOS_ALEATORIOS,
    JUEGO_ACTUAL,
    LIMPIAR_JUEGO_ACTUAL,
    BORRAR_JUEGO,
    JUEGO_PARA_EDITAR,
    LIMPIAR_FORMULARIO_EDITAR_JUEGO,
    ABRIR_DESCARGA,
    CERRAR_DESCARGA,
    ABRIR_TRAILER,
    CERRAR_TRAILER,
    ABRIR_GAMEPLAY,
    CERRAR_GAMEPLAY
} from '../../types';

export default (state, action) => {
    switch(action.type) {
        case OBTENER_JUEGOS:
            return {
                ...state,
                juegos: action.payload
            }
        case JUEGOS_ALEATORIOS:
            return {
                ...state,
                juegosaleatorios: action.payload
            }
        case JUEGO_ACTUAL:
            return {
                ...state,
                juego: action.payload
            }
        case LIMPIAR_JUEGO_ACTUAL:
            return {
                ...state,
                juego: null
            }
        case BORRAR_JUEGO:
            return {
                ...state,
                juegos: state.juegos.filter(juego => juego.id !== action.payload)
            }
        case JUEGO_PARA_EDITAR:
            return {
                ...state,
                juegoeditar: action.payload
            }
        case LIMPIAR_FORMULARIO_EDITAR_JUEGO:
            return {
                ...state,
                juegoeditar: null
            }
        case ABRIR_DESCARGA:
            return{
                ...state,
                popupdescarga: true
            }
        case CERRAR_DESCARGA:
            return{
                ...state,
                popupdescarga: false
            }
        case ABRIR_TRAILER:
            return{
                ...state,
                popuptrailer: true
            }
        case CERRAR_TRAILER:
            return{
                ...state,
                popuptrailer: false
            }
        case ABRIR_GAMEPLAY:
            return{
                ...state,
                popupgameplay: true
            }
        case CERRAR_GAMEPLAY:
            return{
                ...state,
                popupgameplay: false
            }
        default:
            return state;
    }
}