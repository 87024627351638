import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';
import basicAuth from '../../config/basicAuth';

import PortadaContext from './portadaContext';
import PortadaReducer from './portadaReducer';

import {
    OBTENER_IMAGENES,
    BORRAR_IMAGEN,
    NUEVA_IMAGEN
} from '../../types';

const PortadaState = props => {

    const initialState = {
        portadas: null
    }

    const [state, dispatch] = useReducer(PortadaReducer, initialState);

    const getPortadas = async () => {
        try {
            const respuesta = await clienteAxios.get('/portadas');
            // console.log(respuesta.data);
            dispatch({
                type: OBTENER_IMAGENES,
                payload: respuesta.data
            })
        } catch (error) {
            
        }
    }

    const subirArchivos = async (files) => {
        basicAuth();
        try {
            const respuesta = await clienteAxios.post('/portadas', files);
            // console.log(respuesta.data);
        } catch (error) {
            console.log(error);
        }
    }

    const borrarPortada = async (id) => {
        basicAuth();
        try {
            const respuesta = await clienteAxios.delete('/portadas/'+id);
            // console.log(respuesta.data);
            dispatch({
                type: BORRAR_IMAGEN,
                payload: id
            });
        } catch (error) {
            console.log(error);
        }
    }

    return ( 
        <PortadaContext.Provider
            value={{
                portadas:state.portadas,
                getPortadas,
                subirArchivos,
                borrarPortada
            }}
        >
            {props.children}
        </PortadaContext.Provider>
     );
}
 
export default PortadaState;