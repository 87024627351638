import {JUEGO_IGDB, LIMPIAR_FORMULARIO_IGDB, CARGANDO} from '../../types';

export default(state, action) => {
    switch(action.type) {
        case JUEGO_IGDB:
            return {
                ...state,
                juego_igdb:{
                    id: action.payload.id,
                    nombre: action.payload.nombre,
                    descripcion: action.payload.descripcion,
                    genero: action.payload.genero,
                    plataforma: action.payload.plataforma,
                    distribuidor: action.payload.distribuidor,
                    desarrollador: action.payload.desarrollador,
                    lanzamiento: action.payload.lanzamiento,
                    imagen: action.payload.imagen,
                    miniatura: action.payload.miniatura,
                    clasificacion: action.payload.clasificacion,
                    multiimg: action.payload.multiimg,
                    trailer: action.payload.trailer,
                    gameplay: action.payload.gameplay,
                    trainer: '',
                    mods: '',
                    guia: '',
                    almacenamiento: '',
                    servidores: [{servidor: ''}],
                    version: [{nombre: ''}],
                    descargas: [{link: ''}],
                    otro: [{status: false}],
                    nivel: '',
                    requisitos: '',
                    nombre_referido: [{ servidor: '' }],
                    link_referido: [{ link: '' }],
                    otro_referido: [{ status: false }],
                    icono: [{ link: "" }]
                }
            }
        case LIMPIAR_FORMULARIO_IGDB:
            return {
                ...state,
                cargando: null,
                juego_igdb: null
            }
        case CARGANDO:
            return {
                ...state,
                cargando: true
            }
        default:
            return state;
    }
}