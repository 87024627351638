import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';
import basicAuth from '../../config/basicAuth';

import publicidadContext from '../../context/publicidad/publicidadContext';
import publicidadReducer from '../../context/publicidad/publicidadReducer';

import { OBTENER_PUBLICIDAD } from '../../types';

const PublicidadState = props => {

    const initialState = {
        publicidad: null
    }

    const [state, dispatch] = useReducer(publicidadReducer, initialState);

    const subirPublicidad = async data => {
        basicAuth();
        const respuesta = await clienteAxios.post('/publicidad', data);
        // console.log(respuesta);
        // if(respuesta.data.status === 200){
        // }
    }

    const getPublicidad = async data => {
        basicAuth();
        const respuesta = await clienteAxios.get('/publicidad');
        // console.log(respuesta.data);
        if(respuesta.data.status === 200){
            dispatch({
                type: OBTENER_PUBLICIDAD,
                payload: respuesta.data.data
            });
        }
    }

    return ( 
        <publicidadContext.Provider
            value={{
                publicidad: state.publicidad,
                subirPublicidad,
                getPublicidad
            }}
        >
            {props.children}
        </publicidadContext.Provider>
     );
}
 
export default PublicidadState;