import {
    REGISTRO_EXITOSO,
    LOGIN_EXITOSO,
    REGISTRO_ERROR,
    EDITAR_EXITOSO,
    LOGIN_ERROR,
    USUARIO_AUTENTICADO,
    CERRAR_SESION,
    OBTENER_USUARIOS,
    BORRAR_USUARIO,
    COVER_ACTUAL,
    PORTADA_ACTUAL,
    BORRAR_USUARIOS_INACTIVOS
} from '../../types';

export default (state, action) => {
    switch(action.type) {
        case LOGIN_EXITOSO:
        case REGISTRO_EXITOSO:
        case EDITAR_EXITOSO:
            // localStorage.removeItem('zonecrack_token');
            localStorage.setItem('zonecrack_token', action.payload.token);
            // localStorage.setItem('rol', action);
            return {
                ...state,
                autenticado: true,
                token: action.payload.token,
                cargando: false
            }
        case CERRAR_SESION:
        case LOGIN_ERROR:
        case REGISTRO_ERROR:
            localStorage.removeItem('zonecrack_token');
            localStorage.removeItem('rol');
            return{
                ...state,
                autenticado: null,
                token: null,
                usuario: null,
                cargando: false
            }
        case USUARIO_AUTENTICADO:
            localStorage.setItem('rol', action.payload.rol);
            return{
                ...state,
                autenticado:true,
                usuario: action.payload,
                cargando: false,
                rol: action.payload.rol
            }
        case OBTENER_USUARIOS:
            return {
                ...state,
                usuarios: action.payload
            }
        case BORRAR_USUARIO:
            return {
                ...state,
                usuarios: state.usuarios.filter(usuario => usuario.id !== action.payload)
            }
        case COVER_ACTUAL:
            return {
                ...state,
                cover: action.payload
            }
        case PORTADA_ACTUAL:
            return {
                ...state,
                portada: action.payload
            }
        default:
            return state;
    }
}