import React, { useReducer } from "react";
import clienteAxios from "../../config/axios";

import PerfilContext from "./perfilContext";
import PerfilReducer from "./perfilReducer";

import {
  OBTENER_PERFIL
} from "../../types";

const PerfilState = (props) => {
  const initialState = {
    usuarioView: null,
    cargando: true,
    isLogged: false
  };

  const [state, dispatch] = useReducer(PerfilReducer, initialState);

  const getProfile = async (data) => {
    const respuesta = await clienteAxios.get("/user/" + data[0]);
    // console.log(respuesta)
    if (respuesta.status === 200) {
      dispatch({
        type: OBTENER_PERFIL,
        payload: [respuesta.data[0], data[1]]
      });
    }
  };

  return (
    <PerfilContext.Provider
      value={{
        usuarioView: state.usuarioView,
        cargando: state.cargando,
        isLogged: state.isLogged,
        getProfile,
      }}
    >
      {props.children}
    </PerfilContext.Provider>
  );
};

export default PerfilState;
