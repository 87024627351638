import React, { lazy, Suspense, useContext } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import "./App.css";
import "@fortawesome/fontawesome-free/css/all.css";

import tokenAuth from "./config/token";

import RutaPrivada from "./components/rutas/RutaPrivada";

import MainState from "./context/main/mainState";
import AuthState from "./context/autenticacion/authState";
import AdministracionState from "./context/administracion/administracionState";
import BannerState from "./context/banner/bannerState";
import PapeleraState from "./context/papelera/papeleraState";
import FiltroState from "./context/filtro/filtroState";
import EstadoState from "./context/estado/estadoState";
import IgdbState from "./context/igdb/igdbState";
import PublicidadState from "./context/publicidad/publicidadState";
import UserState from "./context/user/userState";
import LinksmenuState from "./context/linksMenu/linksmenuState";
import PortadaState from "./context/portada/portadaState";
import AvatarState from "./context/avatar/avatarState";
import PerfilState from './context/perfil/perfilState';
import Spinner from "./components/layout/LoadingComponent";
import routes from "./helpers/routes";

const main = lazy(() => import("./components/main/Lazy"));
const juegospc = lazy(() => import("./components/juegospc/Juegospc"));
const login = lazy(() => import("./components/auth/Login/login"));
const registro = lazy(() => import("./components/auth/Registro/registro"));
const profile = lazy(() => import("./components/profile/Profile"));
const administracion = lazy(() =>
  import("./components/administracion/administracion")
);
const NotFound = lazy(() => import("./components/NotFound/NotFound"));

const token = localStorage.getItem("zonecrack_token");
if (token) {
  tokenAuth(token);
}

const App = () => {
  return (
    <MainState>
      <AuthState>
        <AdministracionState>
          <BannerState>
            <PapeleraState>
              <FiltroState>
                <EstadoState>
                  <IgdbState>
                    <PublicidadState>
                      <UserState>
                        <LinksmenuState>
                          <PortadaState>
                            <AvatarState>
                              <PerfilState>
                                <Router>
                                  <Suspense fallback={<Spinner />}>
                                    <Switch>
                                      <Route exact path="/" component={main} />
                                      <Route
                                        exact
                                        path="/juegospc"
                                        component={juegospc}
                                      />
                                      <Route
                                        exact
                                        path="/login"
                                        component={login}
                                      />
                                      <Route
                                        exact
                                        path="/registro"
                                        component={registro}
                                      />
                                      <Route
                                        exact
                                        path="/administracion"
                                        component={administracion}
                                      />
                                      <Route
                                        exact
                                        path={routes.profile.profile}
                                        component={profile}
                                      />
                                      <Route path="/404" component={NotFound} exact />
                                    </Switch>
                                  </Suspense>
                                </Router>
                              </PerfilState>
                            </AvatarState>
                          </PortadaState>
                        </LinksmenuState>
                      </UserState>
                    </PublicidadState>
                  </IgdbState>
                </EstadoState>
              </FiltroState>
            </PapeleraState>
          </BannerState>
        </AdministracionState>
      </AuthState>
    </MainState>
  );
};

export default App;
