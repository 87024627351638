import {
    PAGINA_ACTUAL,
    PAGINA_REF,
    FILTRAR_NOMBRE,
    LIMPIAR_FILTRADO,
    FILTRAR_JUEGOSPC,
    GUARDAR_PAGINA
} from '../../types';

export default(state, action) => {
    switch(action.type) {
        case PAGINA_ACTUAL:
            return {
                ...state,
                paginaactual: action.payload
            }
        case PAGINA_REF:
        return {
            ...state,
            paginaref: action.payload
        }
        case FILTRAR_NOMBRE:
            return {
                ...state,
                juegosfiltrados: action.payload[1].filter(juego => juego.nombre.toLowerCase().indexOf(action.payload[0].toLowerCase()) !== -1),
                nombrefiltrado: action.payload[0]
            }
        case LIMPIAR_FILTRADO:
            return {
                ...state,
                juegosfiltrados: null,
                nombrefiltrado: action.payload
            }
        case FILTRAR_JUEGOSPC:
            return {
                ...state,
                juegospcfiltrados: action.payload[1],
                njuegos:action.payload[0]
            }
        case GUARDAR_PAGINA:
            return {
                ...state,
                juegosporpagina: state.payload
            }
        default:
            return state;
    }
}